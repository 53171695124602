import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "account-container" }
const _hoisted_2 = { class: "account-content" }
const _hoisted_3 = { class: "subLabel" }
const _hoisted_4 = { class: "loginform text-left" }
const _hoisted_5 = { class: "ar-field" }
const _hoisted_6 = { class: "formlabel" }
const _hoisted_7 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_8 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_9 = { class: "ar-field" }
const _hoisted_10 = { class: "formlabel" }
const _hoisted_11 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_12 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_13 = { class: "ar-field" }
const _hoisted_14 = { class: "formlabel" }
const _hoisted_15 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_16 = {
  key: 1,
  class: "ar-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHeader, { defaultHeader: true }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('header.account')), 1),
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('editMode')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createElementVNode("form", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('form.oldPassword')), 1),
            _createVNode(_component_PasswordInput, {
              placeholder: _ctx.$t('form.oldPassword'),
              onOnChange: _cache[0] || (_cache[0] = (value) => _ctx.pwform.oldPassword = value)
            }, null, 8, ["placeholder"]),
            (_ctx.errorClass('password', _ctx.pwform.oldPassword)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('error.required')), 1))
              : (_ctx.errorClass('password', _ctx.pwform.oldPassword)=='toShort')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('error.pw_too_short')), 1))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('form.newPassword')), 1),
            _createVNode(_component_PasswordInput, {
              placeholder: _ctx.$t('form.newPassword'),
              onOnChange: _cache[1] || (_cache[1] = (value) => _ctx.pwform.newPassword = value)
            }, null, 8, ["placeholder"]),
            (_ctx.errorClass('password', _ctx.pwform.newPassword)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('error.required')), 1))
              : (_ctx.errorClass('password', _ctx.pwform.newPassword)=='toShort')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('error.pw_too_short')), 1))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('form.newPasswordRepeat')), 1),
            _createVNode(_component_PasswordInput, {
              placeholder: _ctx.$t('form.newPasswordRepeat'),
              onOnChange: _cache[2] || (_cache[2] = (value) => _ctx.passwordRepeat = value)
            }, null, 8, ["placeholder"]),
            (_ctx.errorClass('password', _ctx.passwordRepeat)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('error.required')), 1))
              : _createCommentVNode("", true),
            (_ctx.errorClass('passwordRepeat', _ctx.passwordRepeat)=='dontMatch')
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('error.dontMatch')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_Button, {
          text: _ctx.$t('button.save'),
          waitForResponse: _ctx.waitForResponse,
          onHandleClicked: _ctx.handleClickChangePassword
        }, null, 8, ["text", "waitForResponse", "onHandleClicked"]),
        _createVNode(_component_Button, {
          class: "btn-cancel",
          text: _ctx.$t('button.cancel'),
          dense: true,
          onHandleClicked: _ctx.handleGoBack
        }, null, 8, ["text", "onHandleClicked"])
      ])
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error,
      aboveBottomMenu: true
    }, null, 8, ["show", "text", "error"])
  ]))
}