
import { defineComponent } from 'vue'
import { UserController } from '@/controller';
import { /* FixedHeaderTop, */ PageHeader, Snackbar  } from '@/components/';
import { Button, PasswordInput } from '@/ui';

export default defineComponent({
    name: "ChangePassword",
    components: { Snackbar, /* FixedHeaderTop, */ Button, PasswordInput, PageHeader },
    data(){
        return{
            pwform:{
                oldPassword: '',
                newPassword: '',
            },
            passwordRepeat:'',
            icons:{
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },
            validateForm: false,
            waitForResponse: false,
            snackbar: {
                show: false,
                text: '',
                error: false
            },
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        handleGoBack(){
            this.$router.push('/account')
        },
        errorClass(fieldType : any, input : any){
            if(this.validateForm){
                if(input == ""){
                    return 'required'
                } else if(fieldType == 'password'){
                    if(input.length < 8){
                        return 'toShort'
                    }
                } else if(fieldType == 'passwordRepeat'){
                    if(this.passwordRepeat != this.pwform.newPassword) {
                        return 'dontMatch'
                    }
                } else if( fieldType == 'string'){
                    if(input.length < 3){
                        return 'toShort'
                    }
                }
            }
        },
        /*------------------Change Password------------------*/
        handleClickChangePassword(){
            this.validateForm = true
            if(this.onValidatePasswordForm()){
                this.validateForm = false;
                this.changePassword()
            } else {
                console.log('invalid form')
            }
        },
        onValidatePasswordForm(){
            //NOL: Quick&Dirty Validation
            if(this.pwform.oldPassword.length >=8 && this.pwform.newPassword.length >=8 && this.pwform.newPassword == this.passwordRepeat ){
                return true
            }
            return false
        },
        async changePassword(){
            this.waitForResponse = true
            let res = await UserController.changePassword(this.pwform)
            if(res.data.success){
                this.handleShowSnackbar(this.$t('snackbar.pwSaved'), false)
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.waitForResponse = false
        },
        /*--------------General-----------------*/
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
            }, 5000)
        },
    }
})
